<template>
  <div class="d-flex my-auto  justify-center">
    <v-img
      class="mt-n4 ma-auto"
      style="object-fit: cover;"
      max-height="20%"
      max-width="20%"
      src="@/assets/coloring_logo.png"
    ></v-img>
  </div>
</template>

<script>
export default {
  data() {
    return {
      snackbars: [],
      multiLine: false,
      text: "This is a snackbar!",
    };
  },
  methods: {
    addSnackbar() {
      this.snackbars.push({
        show: true,
        multiLine: this.multiLine,
        text: this.text,
      });
    },
    removeSnackbar(index) {
      this.snackbars.splice(index, 1);
    },
  },
};
</script>
